import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import FaqAccordion from "../../../components/faq/faq-accordion";
import Button from "../../../components/custom-widgets/button";
import MortgageCalculator from "../../../components/calculators/mortgage-calculator/calculator";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import LoanOfficerSearch from "../../../components/loan-officers/loan-officer-search";
import Icon from "../../../components/custom-widgets/icon";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const HelocHomeEquityLineOfCredit = () => {
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/personal-banking/home-loans/heloc/hero-heloc-02-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/home-loans/heloc/hero-heloc-02-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/home-loans/heloc/hero-heloc-02-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/home-loans/heloc/hero-heloc-02-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/home-loans/heloc/hero-heloc-02-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/home-loans/heloc/hero-heloc-02-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/personal-banking/home-loans/heloc/hero-heloc-02-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      card01: file(relativePath: { eq: "cards/thumbnail-heloc-to-customize-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const title = "Home Equity Line of Credit (HELOC) Calculator";
  const description =
    "HELOC with NO ANNUAL FEES - WaFd Bank's home equity line of credit (HELOC) for your next home improvement. Check out WaFd Bank's HELOC calculator and rates.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/heloc-home-equity-line-of-credit"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-heloc-02-250.jpg"
      }
    ]
  };

  const [showLOModal, setShowLOModal] = useState(false);
  const handleCloseLOModal = () => setShowLOModal(false);
  const handleShowLOModal = () => {
    setShowLOModal(true);
  };

  const heroChevronData = {
    id: "heloc-home-equity-line-of-credit-hero",
    ...getHeroImgVariables(pageData),
    altText: "Father and young son hugging in the kitchen.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Whatever Life Brings, a HELOC Is Ready When It Matters"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            text: "Apply Online Today",
            type: "button",
            onClick: handleShowLOModal
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Home Equity Loans"
    }
  ];

  const cardsData = useCardBlogsData([
    "/blog/building-remodeling/10-ways-use-heloc-home-equity-loan-for-remodel",
    "/blog/home-ownership/how-to-qualify-for-a-heloc",
    "/blog/home-ownership/home-equity-loan-college-tuition"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false, idString: "mb-card-" });

  const refinancingBasicsData = {
    sectionClass: "bg-white",
    cards: featuredCardsData.map((card) => ({ ...card, titleClass: "text-gray-90" }))
  };

  const faqAccordionData = {
    id: "heloc-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    seeMoreFaqsId: "heloc-see-more-faqs-link",
    faqCategoryNames: ["HELOC"],
    showContactBar: false
  };

  const AVG_PRICE = 100000;
  const calculatorData = {
    title: "HELOC",
    loanType: "HELOC",
    loanTerm: "10 Year INT. ONLY",
    homeValue: 350000,
    interestRate: 0,
    estimatedPayment: 0,
    purchasePrice: AVG_PRICE,
    cashOutAmount: 0,
    loanAmount: AVG_PRICE
  };

  const helocVideoData = {
    vimeoId: "875369659",
    title: "What Can You Use a HELOC For? | WaFd Bank",
    class: "m-auto iframe h-100 w-100 border-radius-12"
  };

  const benefitsOfDiscountRateOnHELOC = [
    {
      id: 1,
      title: "Renovate your home sweet home"
    },
    {
      id: 2,
      title: "Pay off high interest bills"
    },
    {
      id: 3,
      title: "Invest in education"
    },
    {
      id: 4,
      title: "And just about anything else!"
    }
  ];

  const benefitsOfHomeEquityLineOfCredit = [
    {
      id: 1,
      title: "We pay closing costs for our existing WaFd home loan clients on lines of credit up to $250,000*"
    },
    {
      id: 2,
      title: "Discount of 0.25% on your interest rate when you make automatic payments from your WaFd checking account"
    },
    {
      id: 3,
      title: "Available for owner occupied, second homes, and investment properties"
    },
    {
      id: 4,
      title: "No annual renewal fees"
    },
    {
      id: 5,
      title: "Only pay interest on the amount borrowed**"
    }
  ];

  return (
    <SecondaryLanding footerBorder={true}>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container">
        <h1>Home Equity Loans</h1>
        <div className="row">
          <div className="col-md-7 col-lg-8">
            <h2 className="text-green-60">What is a HELOC?</h2>
            <h3 className="mb-0">
              A home equity line of credit, or a HELOC, is a revolving line of credit. It's secured by the equity you've
              built in your home and can be used as needed&mdash;it's a lot like a credit card, you only pay interest on
              what you draw from your HELOC. In most cases, a HELOC is far less expensive than a credit card and can be
              a more affordable way to borrow.
            </h3>
          </div>
          <div className="col-md-5 col-lg-4">
            <div className="bg-light border-radius-12 p-3">
              <div className="align-items-center justify-content-between mb-3 row">
                <h3 className="mb-0 col">Start Your HELOC Application</h3>
                <img src="../../../images/icons/icon-heloc.svg" alt="" className="col-auto" />
              </div>
              <Button
                class="btn-primary btn-block"
                containerClass="mb-3"
                type="button"
                onClick={handleShowLOModal}
                text="Apply Now"
                id="heloc-apply-now-modal-cta"
                showIcon={false}
              />
              <LoanOfficerSearch isModalSearch={true} show={showLOModal} handleClose={handleCloseLOModal} />
              <Button
                url="/personal-banking/home-loans/mortgage-rates"
                id="mortgage-rates-cta-btn"
                class="btn-link btn-block"
                containerClass="mb-3"
                text="See Today's Rates"
              />
              <div>
                Already started your application?
                <br />
                <a
                  href="/personal-banking/home-loans/mortgage-login"
                  id="returning-borrower-link"
                  className="font-weight-bold text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Return to HELOC application
                  <Icon name="arrow-right" class="ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                quality="100"
                placeholder="blurred"
                src="../../../images/thumbnail-heloc-promotion-072424.jpg"
                alt="A couple with their contractor reviewing construction progress."
              />
            </div>
            <div className="col-md-6">
              <h2 className="text-green-60 font-weight-semi-bold">
                Put Your Home's Equity to Work with 1% Off Your Rate on a HELOC for the first 6 Months
              </h2>
              <ul className={`fa-ul`}>
                {benefitsOfDiscountRateOnHELOC.map((item, index) => (
                  <li key={item.id} className="text-green-90" id={"benefits-discount-rates-li-" + item.id}>
                    <span className="fa-li" style={{ fontSize: "1.2em", lineHeight: "1.25em" }}>
                      <Icon class="text-green-60 mr-2" lib="fas" name="check-circle" />
                    </span>
                    <span>{item.title}</span>
                  </li>
                ))}
              </ul>
              <Button
                id="heloc-apply-online-today-modal-cta"
                type="button"
                class="btn-primary"
                containerClass="my-4"
                onClick={handleShowLOModal}
                text="Apply Online Today"
                showIcon={false}
              />
              <p id="heloc-rate-discount-disclaimer" className="text-sm">
                An introductory rate discount of 1% will be applied to the APR for the first six months at a fixed rate
                (payments are variable after that).
              </p>
              <p id="heloc-to-be-eligible-disclaimer" className="text-sm mb-0">
                To be eligible: Open or maintain a WaFd Bank personal checking account with direct deposit and get set
                up with WaFd's EZ Pay payment option from your WaFd personal checking account.
              </p>
            </div>
          </div>
        </div>
      </section>
      <MortgageCalculator {...calculatorData} />

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <h2 className="text-green-60 font-weight-semi-bold">Benefits of a WaFd Home Equity Line of Credit</h2>
              <ul className={`fa-ul`}>
                {benefitsOfHomeEquityLineOfCredit.map((item, index) => (
                  <li key={item.id} className="text-green-90" id={"benefits-heloc-li-" + item.id}>
                    <span className="fa-li" style={{ fontSize: "1.2em", lineHeight: "1.25em" }}>
                      <Icon class="text-green-60 mr-2" lib="fas" name="check-circle" />
                    </span>
                    <span>{item.title}</span>
                  </li>
                ))}
              </ul>
              <p id="heloc-closing-cost-disclaimer" className="text-sm">
                * Closing costs for our existing WaFd home loan clients are paid only for primary or second/vacation
                homes, not on investment properties.
              </p>
              <p id="heloc-interest-disclaimer" className="text-sm mb-0">
                ** During the draw period, the borrower is only required to pay interest on those funds that they
                actually drew out and used.
              </p>
            </div>
            <div className="col-md-6 order-1 order-md-2 mb-4 mb-md-0">
              <div className="mb-3 mb-md-0">
                <VimeoVideoFrame {...helocVideoData} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <StretchedLinkCards {...refinancingBasicsData} />
      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default HelocHomeEquityLineOfCredit;
